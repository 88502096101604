@import url('https://fonts.googleapis.com/css2?family=Inter');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display');

body {
  margin: 0;
  font-family: 'Inter', 'Nunito Sans', 'Red Hat Display',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: '';
}
